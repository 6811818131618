import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Typography, MenuItem, Select, FormControl, InputLabel, Box, Paper } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import DescriptionIcon from '@material-ui/icons/Description';

const PaginatedEntryList = forwardRef(( {entries, initialItemsPerPage, handleListItemClick}, ref) => {
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPage);

  // Calculate the total number of pages based on the selected items per page
  const totalPages = Math.ceil(entries.length / itemsPerPage);

  // Get entries for the current page
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentEntries = entries.slice(startIndex, endIndex);

  // Handle page change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Handle items per page change
  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setPage(1); // Reset to the first page when items per page changes
  };

  useImperativeHandle(ref, () => ({
    setThePage() {
      setPage(1);
    }
  }));

  // Helper component for Pagination and Items per Page Selector
  const PaginationControls = () => (
    <Box display="flex" justifyContent="center" alignItems="center" marginTop={2} marginBottom={1}>
      <FormControl variant="outlined" style={{ minWidth: 150 }}>
        <InputLabel>Entries per Page</InputLabel>
        <Select
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
          label="Items per Page"
        >
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={75}>75</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
      </FormControl>

      <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        color="primary"
        size="large"
      />
    </Box>
  );

  return (
    <div>
      {/* Top Pagination Controls */}
      <PaginationControls />
      <Paper>
      <List>
        {currentEntries.map((entry) => (
          <ListItem button onClick={(e) => handleListItemClick(e, entry.index)} key={entry.index}>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText
              primary={entry.project}
              secondary={`Created By: ${entry.createdBy} || TRR225 project: ${entry.subProject} || ELN reference(s): ${entry.elnReferences} || Methods/Tags: ${entry.methods}`} 
            />
            {/* Uncomment the following if you want to include the secondary action button */}
            {/* <ListItemSecondaryAction>
              <Button color="secondary" variant="contained" onClick={(e) => handleCompareClick(e, entry.ink_exp_id)}>
                {compareButtonText}
              </Button>
            </ListItemSecondaryAction> */}
          </ListItem>
        ))}
      </List>
      </Paper>
      {/* Bottom Pagination Controls */}
      <PaginationControls />
    </div>
  );
});

export default PaginatedEntryList;
